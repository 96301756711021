<template>
  <v-container fill-height class="full-screen pa-0 ma-0">
    <div class="custom-body">
      <img src="@/assets/images/PLACE_NEGATIVO.png" alt="logo" class="logo" />
      <div class="background-header"></div>
      <v-row class="space-top">
        <v-col cols="6" md="6" sm="12">
          <Authenticate class="pa-16 authenticate-box" />
        </v-col>
        <v-col cols="4" md="4" sm="7" class="centered-logos">
          <img src="@/assets/images/GenteBoaConectada.svg" alt="Gente boa conectada com a nossa gente" class="good-people space-top-good-people" />
        </v-col>
        <v-col cols="2" md="2" sm="5" class="centered-logos">
          <img src="@/assets/images/PLACE_NEG_SIDE.svg" alt="X" class="place" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue';
import Authenticate from '@/views/authentication/Authenticate';
import MainActionTypesEnum from '@/types/main/action-types-enum';

export default Vue.extend({
  name: 'Login',
  components: {
    Authenticate,
  },
  created() {
    localStorage.setItem('token', '');
    this.$store.dispatch(MainActionTypesEnum.LOGOUT_HANDLER);
    const applicationWrap = document.getElementsByClassName('v-application--wrap')[0];
    if (applicationWrap) {
      applicationWrap.style.minHeight = 'inherit';
    }
  },
});
</script>

<style>
.logo {
  height: 85px;
  margin: 20px 0 0 20px;
}
</style>
