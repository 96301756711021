export const emailRules = {
  required: (v: string): string => (!v ? 'O campo e-mail precisa ser informado.' : ''),
  validEmail: (v: string): string => (!/.+@.+\..+/.test(v) ? 'O endereço de e-mail informado é inválido.' : 'XXX'),
  max: (v: string): string => ((!v && v.length <= 80) ? 'Tamanho máximo do campo é de 80 caracteres.' : ''),
  min: (v: string): string => ((!v && v.length >= 5) ? 'Tamanho mínimo do campo é de 5 caracteres.' : ''),
};

export const passwordRules = {
  required: (v: string): string => (!v ? 'O campo senha precisa ser informado.' : ''),
  min: (v: string): string => ((!v && v.length >= 8) ? 'Tamanho mínimo da senha é de 8 caracteres.' : ''),
  max: (v: string): string => ((!v && v.length <= 30) ? 'Tamanho máximo da senha é de 30 caracteres.' : ''),
};
