<template>
  <v-card flat class="login-card">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form id="form-authenticate" ref="form">
        <v-row cols="12" class="mt-4 ml-4 mr-4">
          <p class="font-weight-black pb-0 mb-1">E-mail</p>
        </v-row>

        <v-row cols="12" class="mt-3 ml-4 mr-4" style="display: flow-root;">
          <validation-provider v-slot="{ errors }" name="E-mail" rules="emailRequired|email">
            <v-text-field
              v-model="email"
              autocomplete="username"
              validate-on-blur
              required
              name="E-mail"
              :error-messages="errors"
              placeholder="Informe seu e-mail"
              @keydown.enter.prevent="submitForm"
              filled
            >
            </v-text-field>
          </validation-provider>
        </v-row>

        <v-row cols="12" class="mt-4 ml-4 mr-4">
          <p class="font-weight-black pb-0 mb-1">Senha</p>
        </v-row>

        <v-row cols="12" class="mt-3 ml-4 mr-4" style="display: flow-root;">
          <validation-provider v-slot="{ errors }" name="Senha" rules="required">
            <v-text-field
              v-model="password"
              autocomplete="current-password"
              name="password"
              dense
              placeholder="Informe a senha"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              validate-on-blur
              :error-messages="errors"
              :type="showPassword ? 'text' : 'password'"
              filled
              @click:append="showPassword = !showPassword"
              @keydown.enter.prevent="submitForm"
            >
            </v-text-field>
          </validation-provider>
        </v-row>

        <!--      <v-row cols='12' class='mt-3 ml-4 mr-4'>-->
        <!--        <a class='primary&#45;&#45;text'>Recuperar senha</a>-->
        <!--      </v-row>-->

        <!--      <v-row cols='12 d-flex align-center' class='mt-3 ml-4 mr-4'>-->
        <!--        <v-checkbox></v-checkbox>-->
        <!--        <span class='mt-5'>Manter conectado</span>-->
        <!--      </v-row>-->

        <v-row cols="12" class="mt-3 ml-4 mr-4">
          <v-btn v-promise-btn depressed :disabled="invalid" color="primary" class="text-button" @click="submitForm">
            {{ button.text }}
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { email, required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { get, post } from '@/service/api';
import { routerPush } from '@/service/kplaceRouter';
import { emailRules, passwordRules } from '@/validations/Validations';
import { MutationTypesEnum } from '@/types/main/mutation-types';
import notification from '@/service/notification';
import { typeUserGroup } from '@/utils/user-utils';

extend('required', {
  ...required,
  message: 'Preencha o campo com sua senha.',
});

extend('emailRequired', {
  ...required,
  message: 'Por favor, informe um e-mail válido.',
});

extend('email', {
  ...email,
  message: 'Por favor, informe um e-mail válido.',
});

export default Vue.extend({
  data() {
    return {
      card: {
        title: 'Login',
        subtitle: 'Acesse sua conta com e-mail e senha.',
      },
      formLabels: {
        email: 'E-mail',
        password: 'Senha',
      },
      button: {
        text: 'ENTRAR',
        textForgotPassword: 'Esqueci minha Senha',
      },
      bottomQuestion: {
        question: 'Ainda não tem registro no Sistema?',
        text: 'Criar uma conta',
      },
      email: '',
      password: '',
      emailRules,
      loading: false,
      passwordRules,
      showPassword: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    ...mapActions('authentication', ['setMode', 'handleLogin']),
    ...mapActions('modal', ['showModal']),
    async submitForm() {
      try {
        const formData = new FormData();
        formData.append('username', this.email);
        formData.append('password', this.password);
        formData.append('grant_type', 'password');
        formData.append('client_id', 'mkp');
        formData.append('client_secret', 'nb45Eb@$f');
        const data = await post('auth/oauth/token', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        localStorage.setItem('token', data.data.access_token);
        const current = await get('auth/user/current');
        const currentUser = current.data.principal;
        currentUser.isAdmin = await typeUserGroup(currentUser.groups);
        localStorage.setItem('current_user', JSON.stringify(currentUser));
        await this.$store.commit(MutationTypesEnum.SET_CURRENT_USER, currentUser);
        await this.$store.commit(MutationTypesEnum.SET_IS_ADMIN, currentUser.isAdmin);
        routerPush('/seller');
      } catch (e) {
        notification('Erro ao realizar login. Verifique o e-mail e senha.', 'error');
      }
    },
  },
});
</script>

<style>
.text-button {
  padding: 10px 5px;
}

.text-button span {
  font-family: GothamRounded-Medium, Helvetica, Arial, sans-serif !important;
}

.v-text-field .v-input__slot {
  height: 56px;
}

.v-text-field .v-icon {
  margin-top: 15px;
}
</style>
